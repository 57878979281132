/* You can add global styles to this file, and also import other style files */
@import "~@group-ui/group-ui-angular/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/vwag/vwag.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

html,
body {
  height: 100%;
  margin: 0px;
  overflow: hidden;
}

.value,
.fieldName {
  font-size: smaller;
}
.fieldName {
  font-weight: bolder;
  text-transform: uppercase;
}

.value {
  display: flex;
  justify-content: flex-end;
  white-space: normal;
  text-align: right;
}
.unsetPadding {
  padding: unset;
}

groupui-grid-col{
  padding-top: unset;
  padding-bottom: unset;
}
.unsetPaddingRight {
  padding-right: unset;
}

.textWrapFloatRight {
  white-space: normal;
  text-align: right;
}

.textWrapFloatLeft {
  white-space: normal;
  text-align: left;
}

groupui-card {
  padding: 0.5rem 0;
}

.hidden {
  display: none !important;
}

groupui-grid-row {
  padding: unset !important;
}

groupui-modal {
  z-index: 9999;
  &[displayed] {
    display: block;
  }
}

// Smallest screen width
@media (min-width: 320px) {
  .value,
  .fieldName {
    font-size: x-small;
  }
  .hideS {
    display: none;
  }
  .showS {
    display: inherit;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 560px) {
  .value,
  .fieldName {
    font-size: 11px;
  }
  .hideM {
    display: none;
  }
  .showM {
    display: inherit;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 960px) {
  .value,
  .fieldName {
    font-size: 12px;
  }
  .hideL {
    display: none;
  }
  .showL {
    display: inherit;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1280px) {
  .value,
  .fieldName {
    font-size: smaller;
  }
  .hideXL {
    display: none;
  }
  .showXL {
    display: inherit;
  }
}

// Extra extra large devices (1480px and up)
@media (min-width: 1480px) {
  .value,
  .fieldName {
    font-size: smaller;
  }
  .hideXXL {
    display: none;
  }
  .showXXL {
    display: inherit;
  }
}
